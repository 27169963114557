/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/** primary color for bootstrap.  Wouldn't work in the variables file
I expect it should, but don't know enough about sass at this point **/
$primary: #22a494;

@import "../node_modules/bootstrap/scss/bootstrap";

.btn-primary {
  color: #fff;  //override bootstraps calculation
}

ion-menu-button {
    color: var(--ion-color-primary);
  }

  .break-spaces, 
  ion-select.break-spaces::part(text),
  .select-interface-option.break-spaces .alert-radio-label  {
    white-space: break-spaces;
  }

  //fix select box text overlap in material design style
  ion-alert.select-alert.md {
    --width: 90vw !important;
    --max-width: 350px !important;
  }


  //********* TRIP SELECT COMPONENT ************************//
  //these styles have to live here because the ionic select options are rendered in
  //an alert container inside the main app, not part of the actual component.
  ion-alert .trip-select-option.alert-radio-button-disabled .alert-radio-icon {
    display: none !important;
    
  }

  ion-alert .trip-select-option.alert-radio-button-disabled .alert-radio-label {
    // color: var(--ion-color-medium-contrast);
    font-weight: bold;
    text-align: center;
    padding-inline-start: 0px;
  }

  ion-alert .trip-select-option.alert-radio-button-disabled {
    // background-color: var(--ion-color-medium-tint);
  }

  .highlight {
    background-color: yellow;
  }

  .textarea-fill {
    border: solid 1px;
    border-radius: 3px;
    border-color: var(--ion-color-secondary);
    height:100%;
    box-sizing: border-box;
}

.textarea-fill > div {
    height: 100%;
}

.textarea-fill > div > textarea {
    height: 100%
}